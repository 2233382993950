import { TicketCategory, useTicketCategory } from './TicketCategory';

export const ticketCategoryStore = {
	state: () => ({
		ticketCategories: undefined,
	}),
	mutations: {
		setTicketCategories(state, collection: TicketCategory[]) {
			state.ticketCategories = collection
		},
	},
	actions: {
		async loadTicketCategories({ commit, state }) {
			if (state.ticketCategories !== undefined) {
				return Promise.resolve();
			}

			commit('setTicketCategories', await useTicketCategory().getAllNoPagination());
		},
	},
	getters: {
		ticketCategories(state): TicketCategory[] {
			return state.ticketCategories || [];
		},
	},
};
