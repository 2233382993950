import ApiResource from '@/core/http/ApiResource';
import { User } from '@/modules/user/User';
import { Partner, PartnerStub } from '@/modules/partner/Partner';
import { LawFirm } from '@/modules/law_firm/LawFirm';
import { MeCustomer } from '@/modules/customer/Customer';
import { TicketStatistics } from '@/modules/ticket/Ticket';

export interface MeUpdate extends User {
	password: string | undefined;
	current_password: string | undefined;
}

export class MeResource {

	private readonly resource: ApiResource<User>;

	constructor() {
		this.resource = new ApiResource<User>('/api/me');
	}

	get(): Promise<User> {
		return this.resource.request('GET', '');
	}

	getPartner(): Promise<PartnerStub> {
		return this.resource.request('GET', 'partner');
	}

	getPartnerTicketStatistics(): Promise<TicketStatistics> {
		return this.resource.request('GET', 'partner/ticket/statistics');
	}

	putPrimaryLawFirm(lawFirmId: number): Promise<Partner> {
		return this.resource
			.request('PUT', `partner/primary_law_firm`, {
				law_firm: lawFirmId,
			})
		;
	}

	getLawFirm(): Promise<LawFirm> {
		return this.resource.request('GET', 'law_firm');
	}

	getCustomer(): Promise<MeCustomer> {
		return this.resource.request('GET', 'customer');
	}

	update(data: MeUpdate): Promise<User> {
		return this.resource.request('PUT', '', data);
	}
}

export const useMe = () => new MeResource();
