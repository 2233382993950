import { ErrorBag } from '@/core/common';

interface Error {
	code: string;
	message: string,
	target?: string;
	details?: ErrorBag;
}

export interface ErrorResponse {
	error: Error;
}

export class ApiError extends Error {
	public response: Response;
	public data: ErrorResponse;

	public static fromResponse(response: Response, json: ErrorResponse | null): ApiError {
		let data: ErrorResponse;
		if (json) {
			data = json;
		} else {
			data = {
				error: {
					message: 'Server Error',
					code: '' + response.status,
				},
			};
		}

		return new ApiError(response, data);
	}

	constructor(response: Response, data: ErrorResponse) {
		super(data.error.message);
		this.response = response;
		this.data = data;
	}

	public isValidationError(): boolean {
		return this.response.status === 422;
	}

	public isRequestError(): boolean {
		return this.response.status >= 400 && this.response.status < 500;
	}

	public isServerError(): boolean {
		return this.response.status >= 500;
	}

	public getErrors(): ErrorBag {
		return this.data.error.details || {};
	}
}