import { createApp } from 'vue';
import { router } from './router';
import gettext from '@/gettext';
import store from './store';
import auth from '@/core/Auth';
import App from './App.vue';
import 'normalize.css/normalize.css';
import '@/assets/fontawesome/fontawesome.min.css';
import '@/assets/app.css';

// Modules
import '@/modules/dashboard';
import '@/modules/login';
import '@/modules/user';
import '@/modules/ticket';
import '@/modules/law_firm';
import '@/modules/ticket_category';
import '@/modules/ticket_status';
import '@/modules/product';
import '@/modules/partner';
import '@/modules/customer';
import '@/modules/news';
import '@/modules/cms_page';

router.addRoute({
	path: '/:catchAll(.*)',
	component: () => import(/* webpackChunkName: "errors" */'@/NotFound.vue'),
});

auth.check().then(async () => {
	await store.dispatch('setPublicPartnerWhitelabeling', location.hostname);

	if (store.state.authenticatedUser?.requires_verification) {
		await router.replace({
			name: 'login.mfa',
		});
	}

	createApp(App)
		.use(store)
		.use(router)
		.use(gettext)
		.mount('#app');
});
