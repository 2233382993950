import { addRoutes } from '@/router';

addRoutes([
	{
		path: '/product',
		name: 'product.index',
		component: () => import(/* webpackChunkName: "product" */'./pages/ProductIndex.vue'),
		auth: true,
	},
	{
		path: '/product/create/:type',
		name: 'product.create',
		component: () => import(/* webpackChunkName: "product" */'./pages/ProductCreate.vue'),
		auth: true,
	},
	{
		path: '/product/:id/edit',
		name: 'product.edit',
		component: () => import(/* webpackChunkName: "product" */'./pages/ProductEdit.vue'),
		auth: true,
	},
]);