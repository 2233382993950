import { Api, HttpBodyConvertable, RequestMethod } from '@/core/http/Api';
import PaginatedResponse from '@/core/http/PaginatedResponse';
import { Loader } from '@/core/Loader';

export type ApiParams = Record<string, string>;

export default class ApiResource<Entity, EntityStub = Entity> {
	protected readonly baseUrl: string;
	protected readonly withLoader: boolean = true;
	protected readonly uxDelay: boolean = true;
	protected globalParams: Map<string, string>;

	constructor(baseUrl: string, withLoader = true, uxDelay = true) {
		this.baseUrl = baseUrl;
		this.withLoader = withLoader;
		this.uxDelay = uxDelay;
		this.globalParams = new Map();
	}

	protected getApi(withLoader: boolean): Api {
		return new Api(
			this.uxDelay,
			() => {
				if (withLoader) {
					Loader.show();
				}
			},
			() => {
				if (this.withLoader) {
					Loader.hide();
				}
			},
		);
	}

	public globalParam(key: string, value: string | null = null) {
		if (value) {
			this.globalParams.set(key, value);
		} else {
			this.globalParams.delete(key);
		}
	}

	applyParamsToUrl(url: string, params: ApiParams): string {
		const queryParams = new URLSearchParams(params);
		this.globalParams.forEach((val, key) => {
			queryParams.set(key, val);
		});

		if (Array.from(queryParams).length) {
			url = url + '?' + queryParams.toString();
		}

		return url;
	}

	async getAllRequest(url: string, params: ApiParams = {}, withLoader = this.withLoader): Promise<PaginatedResponse<EntityStub>> {
		url = this.applyParamsToUrl(url, params);

		const api = this.getApi(withLoader);
		const data = await api.get<EntityStub[]>(url);

		const response = api.getLastResponse();
		if (!response) {
			throw new Error('No response present after sending a request.');
		}

		return new PaginatedResponse<EntityStub>(data, response.headers);
	}

	async getAll(params: ApiParams = {}, withLoader = this.withLoader): Promise<PaginatedResponse<EntityStub>> {
		return this.getAllRequest(this.baseUrl, params, withLoader)
	}

	async getAllNoPagination(params: ApiParams = {}, withLoader = this.withLoader): Promise<EntityStub[]> {
		let response = await this.getAll(params, withLoader);
		let data: EntityStub[] = response.data;

		while (response.nextUrl !== null) {
			response = await this.getAll({
				...params,
				page: response.nextUrl,
			}, withLoader);

			data = data.concat(response.data);
		}

		return data;
	}

	async getCount(params = {}): Promise<number> {
		const api = this.getApi(true);
		await api.request('HEAD', this.applyParamsToUrl(this.baseUrl, params));

		const response = api.getLastResponse();
		if (!response) {
			throw new Error('No response present after sending a request.');
		}

		return parseInt(response.headers.get('resource-count') as string, 10);
	}

	request<T = void>(method: RequestMethod, path: string | null, body: HttpBodyConvertable = {}, withLoader = this.withLoader): Promise<T> {
		let endpoint = this.baseUrl;
		if (path) {
			endpoint += `/${path}`;
		}

		return this.getApi(withLoader).request<T>(method, endpoint, body);
	}

	get(id: string | number, withLoader = this.withLoader): Promise<Entity> {
		return this.getApi(withLoader).get<Entity>(`${this.baseUrl}/${id}`);
	}

	post(data: Partial<Entity>, withLoader = this.withLoader): Promise<Entity> {
		return this.getApi(withLoader).post<Entity>(this.baseUrl, data);
	}

	put(id: string | number, data: Partial<Entity>, withLoader = this.withLoader): Promise<Entity> {
		return this.getApi(withLoader).put<Entity>(`${this.baseUrl}/${id}`, data);
	}

	delete(id: string | number, withLoader = this.withLoader): Promise<void> {
		return this.getApi(withLoader).delete(`${this.baseUrl}/${id}`);
	}

}
