import { createStore } from 'vuex';
import { SessionUser } from '@/core/Auth';
import { FlashMessage } from '@/core/Flash';
import { Modal } from '@/core/components/appModalControl';
import { Language } from '@/modules/parameters/Language';
import { Market } from '@/modules/parameters/Market';
import { ticketStatusStore } from '@/modules/ticket_status/store';
import { ticketCategoryStore } from '@/modules/ticket_category/store';
import { Permission } from '@/modules/user/Permission';
import { productStore } from '@/modules/product/store';
import { Partner, PartnerPublic, PartnerWhitelabeling, usePartner } from '@/modules/partner/Partner';
import { useMe } from '@/modules/user/Me';
import { cmsPageStore } from '@/modules/cms_page/store';
import { MeCustomer } from '@/modules/customer/Customer';

interface PermissionCache {
	[key: string]: Permission[] | undefined;
}

interface PermissionPayload {
	type: string;
	collection: Permission[];
}

export interface AppStore {
	authenticatedUser: SessionUser | null;
	authAssociatedPartner: Partner | null;
	authAssociatedCustomer: MeCustomer | null;
	flashMessages: FlashMessage[];
	loadingCount: number;
	modals: Set<Modal>;
	languages: Language[] | undefined,
	markets: Market[] | undefined,
	permissions: PermissionCache;
	whitelabelColors: Record<string, string>[] | undefined;
	publicPartner: PartnerPublic | undefined;
}

export default createStore<AppStore>({
	state: {
		authenticatedUser: null,
		authAssociatedPartner: null,
		authAssociatedCustomer: null,
		flashMessages: [],
		loadingCount: 0,
		modals: new Set<Modal>(),
		languages: undefined,
		markets: undefined,
		permissions: {
			'MASTER': undefined,
			'PARTNER': undefined,
			'LAW_FIRM': undefined,
			'CUSTOMER': undefined,
		},
		whitelabelColors: undefined,
		publicPartner: undefined,
	},
	mutations: {
		setAuthenticatedUser: (state, user: SessionUser) => state.authenticatedUser = user,
		setAuthAssociatedPartner: (state, partner: Partner | null) => state.authAssociatedPartner = partner,
		setAuthAssociatedCustomer: (state, customer: MeCustomer | null) => state.authAssociatedCustomer = customer,

		// FLASH MESSAGES
		addFlash: (state, flash: FlashMessage) => state.flashMessages.push(flash),
		removeFlash: (state, id: number) => state.flashMessages = state.flashMessages.filter(f => f.id !== id),
		clearFlash: (state) => state.flashMessages = [],

		// LOADING INDICATOR
		incrementLoader: (state) => state.loadingCount++,
		decrementLoader: (state) => {
			if (state.loadingCount > 0) {
				state.loadingCount--;
			}
		},

		// MODAL COUNT
		addModal: (state, modal: Modal) => state.modals.add(modal),
		removeModal: (state, modal: Modal) => state.modals.delete(modal),

		// LANGUAGES
		setLanguages: (state, collection: Language[]) => state.languages = collection,

		// MARKETS
		setMarkets: (state, collection: Market[]) => state.markets = collection,

		// PERMISSIONS
		setPermissions: (state, payload: PermissionPayload) => state.permissions[payload.type] = payload.collection,

		// WHITELABEL COLORS
		setWhitelabelColors: (state, collection: Record<string, string>[]) => state.whitelabelColors = collection,

		// ACTIVE WHITELABELING
		setPublicPartner: (state, payload: PartnerPublic) => state.publicPartner = payload,
	},
	actions: {
		setAuthenticatedUser: ({commit, dispatch}, payload: SessionUser | null) => {
			commit('setAuthenticatedUser', payload);
			// noinspection JSIgnoredPromiseFromCall
			dispatch('getAuthAssociatedPartner');
		},
		getAuthAssociatedPartner: async ({state, commit}) => {
			if (!state.authenticatedUser) {
				commit('setAuthAssociatedPartner', null);
				commit('setAuthAssociatedCustomer', null);
				return;
			}

			if (state.authenticatedUser.type === 'PARTNER') {
				const partner = await useMe().getPartner();
				if (!partner.whitelabeling) {
					return;
				}

				commit('setAuthAssociatedPartner', partner);

			} else if (state.authenticatedUser.type === 'CUSTOMER') {
				const customer = await useMe().getCustomer();

				if (customer.whitelabeling) {
					commit('setAuthAssociatedPartner', customer.partner);
				}

				commit('setAuthAssociatedCustomer', customer);

			} else {
				commit('setAuthAssociatedPartner', null);
				commit('setAuthAssociatedCustomer', null);
			}
		},
		setPublicPartnerWhitelabeling: async ({state, commit}, domain) => {
			if (state.publicPartner) {
				return;
			}

			try {
				const partner = await usePartner().getByPublicDomain(domain);
				commit('setPublicPartner', partner);
			} catch (e) {
				return;
			}
		},
	},
	getters: {
		userLanguage: (state): string => {
			if (state.authenticatedUser) {
				return state.authenticatedUser.language;
			}

			if (state.publicPartner) {
				return state.publicPartner.market.language as string;
			}

			return 'sv';
		},
		languages: (state): Language[] => state.languages || [],
		markets: (state): Market[] => state.markets || [],
		permissions: (state): PermissionCache => state.permissions,
		whitelabelColors: (state): Record<string, string>[] => state.whitelabelColors || [],
		activeWhitelabeling: (state): PartnerWhitelabeling | undefined => {
			return state.authAssociatedPartner?.whitelabeling || state.publicPartner?.whitelabeling;
		},
		appName: (state, getters): string => {
			if (getters.activeWhitelabeling) {
				return state.authAssociatedPartner?.name || state.publicPartner?.name || '';
			}

			return 'Secure Channel';
		},
	},
	modules: {
		ticketStatus: ticketStatusStore,
		ticketCategory: ticketCategoryStore,
		product: productStore,
		cmsPage: cmsPageStore,
	},
});
