import { addRoutes } from '@/router';

addRoutes([
	{
		path: '/cms_page',
		name: 'cmsPage.index',
		component: () => import(/* webpackChunkName: "cmsPage" */'./pages/CmsPageIndex.vue'),
		auth: true,
	},
	{
		path: '/cms_page/create',
		name: 'cmsPage.create',
		component: () => import(/* webpackChunkName: "cmsPage" */'./pages/CmsPageCreate.vue'),
		auth: true,
	},
	{
		path: '/cms_page/:id/edit',
		name: 'cmsPage.edit',
		component: () => import(/* webpackChunkName: "cmsPage" */'./pages/CmsPageEdit.vue'),
		auth: true,
	},
	{
		path: '/cms_page/:id',
		name: 'cmsPage.view',
		component: () => import(/* webpackChunkName: "dashboard" */'./pages/CmsPageView.vue'),
		auth: true,
	},
]);