import ApiResource from '@/core/http/ApiResource';
import store from '@/store';
import { $gettext } from '@/gettext';
import { Api } from '@/core/http/Api';
import { Market } from '@/modules/parameters/Market';

type InvoiceTarget = 'CUSTOMER' | 'PARTNER';
type WhitelabelingImage = 'logotype' | 'background';

export const invoiceTargetNames: Record<InvoiceTarget, string> = {
	'CUSTOMER': $gettext('Kunden'),
	'PARTNER': $gettext('Återförsäljaren'),
};

interface PartnerLawFirmStub {
	id: number;
	name: string;
	identity_number: string;
}

export interface PartnerStub {
	id: number;
	identity_number: string;
	name: string;
	contact_name: string;
	email: string;
	phone: string;
	market: Partial<Market>;
	allowed_demo_customers: number;
	signed_gdpr: boolean;
	signed_privacy: boolean;
	created_time: string;
	primary_law_firm: PartnerLawFirmStub | undefined;
	whitelabeling: PartnerWhitelabeling | undefined;
}

interface PartnerProductPrice {
	product_id: number;
	price: number;
}

interface PartnerInvoiceAddress {
	target: InvoiceTarget | null;
	address: string | null;
	zipcode: string | null;
	city: string | null;
	email: string | null;
}

export interface PartnerWhitelabeling {
	domain: string;
	logotype_path: string | null;
	background_path: string | null;
	welcome_start: string | null;
	welcome_end: string | null;
	menu_text: string | null;
	menu_icon: string | null;
	page_header_start: string | null;
	page_header_end: string | null;
	table_header_start: string | null;
	table_header_end: string | null;
	primary_button: string | null;
	link: string | null;
	link_hover: string | null;
}

export interface Partner extends PartnerStub {
	product_prices: PartnerProductPrice[];
	invoice: PartnerInvoiceAddress;
}

export interface PartnerPublic {
	id: number;
	name: string;
	market: Partial<Market>;
	whitelabeling: PartnerWhitelabeling;
}

class PartnerResource extends ApiResource<Partner> {
	getByPublicDomain(domain: string, withLoader = this.withLoader): Promise<PartnerPublic> {
		return this.getApi(withLoader)
			.get<PartnerPublic>(`${this.baseUrl}/public/${domain}`)
		;
	}

	putPrimaryLawFirm(partnerId: number, lawFirmId: number, withLoader = this.withLoader): Promise<Partner> {
		return this.getApi(withLoader)
			.put(`${this.baseUrl}/${partnerId}/primary_law_firm`, {
				law_firm: lawFirmId,
			})
		;
	}

	putWhitelabeling(partnerId: number, data: Partial<PartnerWhitelabeling>, withLoader = this.withLoader): Promise<Partner> {
		return this.getApi(withLoader)
			.put(`${this.baseUrl}/${partnerId}/whitelabeling`, data)
		;
	}

	putWhitelabelingImage(type: WhitelabelingImage, partnerId: number, data: File, withLoader = this.withLoader): Promise<Partner> {
		const formData = new FormData();
		formData.set('file', data);

		return this.getApi(withLoader)
			.put(`${this.baseUrl}/${partnerId}/whitelabeling/${type}`, formData)
		;
	}

	deleteWhitelabeling(partnerId: number, withLoader = this.withLoader): Promise<Partner> {
		return this.getApi(withLoader)
			.delete(`${this.baseUrl}/${partnerId}/whitelabeling`)
		;
	}
}

export const usePartner = () => new PartnerResource('/api/partner');

export const getAllWhitelabelColors = async (): Promise<Record<string, string>[]> => {
	if (typeof store.state.whitelabelColors !== 'undefined') {
		return store.state.whitelabelColors;
	}

	const response = await new Api().get<Record<string, string>[]>('/api/parameters/whitelabel/color');

	store.commit('setWhitelabelColors', response);

	return response;
}
