import ApiResource from '@/core/http/ApiResource';
import { ContentModel } from '@/core/languageContent';

export interface TicketCategory {
	id: number;
	content: ContentModel[];
	created_time: string;
}

export const useTicketCategory = () => new ApiResource<TicketCategory>('/api/ticket_category');
