type PageCursor = {
	page: number;
	cursor: string;
}

export default class PaginatedResponse<T> {
	public readonly data: T[];
	public readonly currentPage: number;
	public readonly itemsTotal: number;
	public readonly pages: number;
	public readonly pageStart: number;
	public readonly pageEnd: number;
	public readonly sort: string;
	public previousUrl: string | null = null;
	public nextUrl: string | null = null;
	public pageSize = 20;

	constructor(data: T[], headers: Headers) {
		this.data = data;

		if (headers.has('link')) {
			this.parseLink(headers.get('link') as string);
		}

		this.currentPage = parseInt(headers.get('pagination-page') as string, 10);
		this.itemsTotal = parseInt(headers.get('pagination-items-total') as string, 10);
		this.pages = parseInt(headers.get('pagination-num-pages') as string, 10);
		this.sort = headers.get('sort') || '' as string;
		this.pageStart = Math.min(this.itemsTotal, (this.currentPage - 1) * this.pageSize + 1);
		this.pageEnd = Math.min(this.itemsTotal, this.pageStart + this.pageSize - 1);
	}

	private parseLink(link: string): void {
		link.split(/, ? /).map(part => {
			const info = /<([^;]+)>;rel="([^"]+)"/g.exec(part);

			if (!info || info.length < 3) {
				return;
			}

			const url = new URL('https://www' + info[1]);
			const search = url.searchParams;

			if (info[2] === 'previous') {
				this.previousUrl = search.get('page');
				this.parsePageSize(this.previousUrl);
			} else if (info[2] === 'next') {
				this.nextUrl = search.get('page');
				this.parsePageSize(this.nextUrl);
			}
		});
	}

	private parsePageSize(link) {
		const parsed = JSON.parse(atob(link));

		if (parsed.page && parsed.page_size) {
			this.pageSize = parsed.page_size;
		}
	}

	createCursor(page: number): PageCursor {
		const cursor = btoa(JSON.stringify({
			'page': page,
			'page_size': this.pageSize,
		}));

		return {
			page,
			cursor,
		};
	}
}