import { addRoutes } from '@/router';

addRoutes([
	{
		path: '/',
		name: 'login',
		component: () => import(/* webpackChunkName: "login" */'@/modules/login/pages/LoginPage.vue'),
		auth: false,
	},
	{
		path: '/login/mfa',
		name: 'login.mfa',
		component: () => import(/* webpackChunkName: "login" */'@/modules/login/pages/LoginMfaPage.vue'),
		auth: true,
	},
	{
		path: '/forgot-password',
		name: 'login.forgot',
		component: () => import(/* webpackChunkName: "login" */'@/modules/login/pages/PasswordResetRequest.vue'),
		auth: false,
	},
	{
		path: '/forgot-password/:selector/:verifier',
		name: 'login.password-reset',
		component: () => import(/* webpackChunkName: "login" */'@/modules/login/pages/PasswordReset.vue'),
		auth: false,
	},
]);
