import { addRoutes } from '@/router';

addRoutes([
	{
		path: '/user',
		name: 'user.index',
		component: () => import(/* webpackChunkName: "user" */'./pages/UserIndex.vue'),
		auth: true,
	},
	{
		path: '/user/create',
		name: 'user.create',
		component: () => import(/* webpackChunkName: "user" */'./pages/UserCreate.vue'),
		auth: true,
	},
	{
		path: '/profile',
		name: 'user.profile',
		component: () => import(/* webpackChunkName: "user" */'./pages/UserProfile.vue'),
		auth: true,
	},
	{
		path: '/user/:id/edit',
		name: 'user.edit',
		component: () => import(/* webpackChunkName: "user" */'./pages/UserEdit.vue'),
		auth: true,
	},
]);