import { createGettext } from '@jshmrtn/vue3-gettext';
import translations from './language/translations.json';

const gettext = createGettext({
	availableLanguages: {
		sv: 'Svenska',
		en: 'English',
		de: 'Deutsch',
	},
	defaultLanguage: 'sv',
	mutedLanguages: ['sv'],
	translations,
});

export default gettext;

export const $gettext = gettext.$gettext;

// Trigger the "easygettext" package extractor which isn't compatible with <script setup>
$gettext('Användare för %{ name }');
$gettext('Extern partner för %{ name }');
$gettext('Publik');
