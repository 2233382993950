import { TicketStatus, useTicketStatus } from './TicketStatus';

export const ticketStatusStore = {
	state: () => ({
		ticketStatuses: undefined,
	}),
	mutations: {
		setTicketStatuses(state, collection: TicketStatus[]) {
			return state.ticketStatuses = collection;
		},
	},
	actions: {
		async loadTicketStatuses({ commit, state }) {
			if (state.ticketStatuses !== undefined) {
				return Promise.resolve();
			}

			commit('setTicketStatuses', await useTicketStatus().getAllNoPagination());
		},
	},
	getters: {
		ticketStatuses(state): TicketStatus[] {
			return state.ticketStatuses || [];
		},
	},
};
