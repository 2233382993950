import { addRoutes } from '@/router';

addRoutes([
	{
		path: '/news',
		name: 'news.index',
		component: () => import(/* webpackChunkName: "news" */'./pages/NewsIndex.vue'),
		auth: true,
	},
	{
		path: '/news/create',
		name: 'news.create',
		component: () => import(/* webpackChunkName: "news" */'./pages/NewsCreate.vue'),
		auth: true,
	},
	{
		path: '/news/:id/edit',
		name: 'news.edit',
		component: () => import(/* webpackChunkName: "news" */'./pages/NewsEdit.vue'),
		auth: true,
	},
]);