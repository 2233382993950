import { $gettext } from '@/gettext';
import ApiResource from '@/core/http/ApiResource';
import { ContentModel } from '@/core/languageContent';
import { Market } from '@/modules/parameters/Market';

type ProductType = 'SUBSCRIPTION' | 'ADDON';

export const typeNames = {
	SUBSCRIPTION: $gettext('Abonnemang'),
	ADDON: $gettext('Tillägg'),
};

export interface Product {
	id: number;
	type: ProductType;
	market: Partial<Market>,
	sku: string,
	price: number,
	vat: number,
	content: ContentModel[];
	created_time: string;
}

export interface ProductSubscription extends Product {
	admins_included: number;
	languages_included: number;
	notice_period: number | null;
	included_addons: number[];
}

export interface ProductAddon extends Product {
	code: string,
}

class ProductResource extends ApiResource<ProductSubscription | ProductAddon> {
	post(data: Partial<ProductSubscription | ProductAddon>, withLoader: boolean = this.withLoader): Promise<ProductSubscription | ProductAddon> {
		return this.getApi(withLoader).post(`${this.baseUrl}/${data.type}`, data);
	}
}

export const useProduct = () => new ProductResource('/api/product');
