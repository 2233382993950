import { ApiError, ErrorResponse } from '@/core/http/ApiError';
import { sleep } from '@/core/common';

export type BeforeHook = (endpoint: string, request: RequestInit) => void;
export type AfterHook = (response: Response) => void;
export type RequestMethod = 'HEAD' | 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export type HttpBodyConvertable = Record<string, unknown> | FormData | unknown;

export class Api {

	private lastResponse: Response | null = null;
	private readonly uxDelay: boolean;
	private readonly beforeHook: BeforeHook | null = null;
	private readonly afterHook: AfterHook | null = null;

	constructor(uxDelay = false, beforeHook: BeforeHook | null = null, afterHook: AfterHook | null = null) {
		this.uxDelay = uxDelay;
		this.beforeHook = beforeHook;
		this.afterHook = afterHook;
	}

	public get<T>(endpoint: string): Promise<T> {
		return this.request<T>('GET', endpoint);
	}

	public post<T>(endpoint: string, body: HttpBodyConvertable = {}): Promise<T> {
		return this.request<T>('POST', endpoint, body);
	}

	public put<T>(endpoint: string, body: HttpBodyConvertable = {}): Promise<T> {
		return this.request<T>('PUT', endpoint, body);
	}

	public patch<T>(endpoint: string, body: Record<string, unknown> = {}): Promise<T> {
		return this.request<T>('PATCH', endpoint, body);
	}

	public delete<T = void>(endpoint: string): Promise<T> {
		return this.request<T>('DELETE', endpoint);
	}

	public async request<T>(method: RequestMethod, endpoint: string, body: HttpBodyConvertable = {}): Promise<T> {
		const request: RequestInit = { method };
		const headers = { Accept: 'application/json' };

		if (body && ['POST', 'PATCH', 'PUT'].includes(method)) {
			if (body instanceof FormData) {
				request.body = body;
			} else {
				headers['Content-Type'] = 'application/json';
				request.body = JSON.stringify(body);
			}
		}

		request.headers = headers;

		return this.send<T>(request, endpoint);
	}

	private async send<T>(request: RequestInit, endpoint: string): Promise<T> {
		if (this.beforeHook) {
			this.beforeHook(endpoint, request);
		}

		const startTime = Date.now();

		const response = await fetch(endpoint, request);
		this.lastResponse = response;

		/**
		 * Perhaps controversial:
		 * For better UX we want to account for the slowness of human perception.
		 * We want to ensure the loading indicator has had enough time to appear
		 * without having the interface just flash temporarily. We also want to
		 * delay returning the response long enough for the user to actually
		 * have time to notice that something changed.
		 *
		 * Delay requests by the remainder of a set a minimum,
		 * but only for requests that are faster than the minimum delay.
		 */
		if (this.uxDelay) {
			const minDelay = 500;
			const delay = minDelay - (Date.now() - startTime);
			if (delay > 0) {
				await sleep(delay);
			}
		}

		if (this.afterHook) {
			this.afterHook(response);
		}

		let json;
		if (response.status !== 204 && request.method !== 'HEAD') {
			json = await response.json();
		}

		if (response.ok) {
			return json as T;
		}

		throw ApiError.fromResponse(response, json as ErrorResponse);
	}

	public getLastResponse(): Response | null {
		return this.lastResponse;
	}
}
