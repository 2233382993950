import { RouteLocationRaw } from 'vue-router';
import store from '@/store';
import { Api } from '@/core/http/Api';
import { User } from '@/modules/user/User';
import { router } from '@/router';
import { Loader } from '@/core/Loader';

let loginRedirect: string | null = null;
const api: Api = new Api();

export interface SessionUser extends User {
	requires_verification: boolean;
}

const wrapAsSessionUser = (user: User): SessionUser => ({
	...user,
	requires_verification: false,
});

const auth = {
	async check(): Promise<void> {
		try {
			const user = await api.get<SessionUser>('/api/session');
			this.setUser(user);
		} catch (e) {
			//
		}
	},

	async authenticate(email: string, password: string): Promise<SessionUser> {
		Loader.show();
		const user = await api.post<SessionUser>('/api/session', { email, password });
		this.setUser(user);
		Loader.hide();

		return user;
	},

	async replyMfaChallenge(code: string): Promise<SessionUser> {
		Loader.show();
		const user = await api.put<SessionUser>('/api/session/mfa_challenge', { code });
		this.setUser(user);
		Loader.hide();

		return user;
	},

	async impersonate(userId: number): Promise<User> {
		Loader.show();
		const user = await api.post<User>('/api/session', {
			impersonate: userId,
		});
		this.setUser(wrapAsSessionUser(user));
		Loader.hide();

		return user;
	},

	setLoginRedirect(path: string): void {
		loginRedirect = path;
	},

	getLoginRedirect(): RouteLocationRaw {
		if (loginRedirect) {
			return { path: loginRedirect };
		}

		return { name: 'dashboard' };
	},

	isAuthenticated(): boolean {
		return store.state.authenticatedUser !== null;
	},

	invalidate(): void {
		api.delete<User | null>('/api/session')
			.then(user => {
				if (user) {
					this.setUser(wrapAsSessionUser(user));
					// noinspection JSIgnoredPromiseFromCall
					router.push({ name: 'dashboard' });

					return;
				}

				// noinspection JSIgnoredPromiseFromCall
				store.dispatch('setAuthenticatedUser', null);
				store.commit('unsetCmsPages');
				// noinspection JSIgnoredPromiseFromCall
				router.push({ name: 'login' });
			})
			.catch(e => void e)
		;
	},

	setUser(user: SessionUser): void {
		// noinspection JSIgnoredPromiseFromCall
		store.dispatch('setAuthenticatedUser', user);
		store.commit('unsetCmsPages');
	},

	passwordResetRequest(email: string): Promise<void> {
		return api.post('/api/user_password_reset', { email });
	},

	getPasswordReset(selector: string, verifier: string): Promise<void> {
		return api.get(`/api/user_password_reset/${selector}/${verifier}`);
	},

	passwordReset(selector: string, verifier: string, password: string): Promise<void> {
		return api.put(`/api/user_password_reset/${selector}/${verifier}`, { password });
	},

	can(permission: string): boolean {
		if (!store.state.authenticatedUser || !store.state.authenticatedUser.permissions) {
			return false;
		}

		return store.state.authenticatedUser.permissions.indexOf(permission) >= 0;
	},

	canAll(permissions: string[]): boolean {
		return permissions.every(p => auth.can(p));
	},

	canAny(permissions: string[]): boolean {
		return permissions.some(p => auth.can(p));
	},
};

export default auth;
