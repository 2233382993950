import { CmsPage, useCmsPage } from './CmsPage';

export const cmsPageStore = {
	state: () => ({
		cmsPages: undefined,
	}),
	mutations: {
		setCmsPages(state, collection: CmsPage[]) {
			state.cmsPages = collection;
		},
		unsetCmsPages(state) {
			state.cmsPages = undefined;
		},
	},
	actions: {
		async loadCmsPages({ commit, state }) {
			if (state.cmsPages !== undefined) {
				return Promise.resolve();
			}

			commit('setCmsPages', await useCmsPage().getAllNoPagination());
		},
	},
	getters: {
		cmsPages(state): CmsPage[] {
			return state.cmsPages || [];
		},
	},
};
