import { Product, ProductAddon, ProductSubscription, useProduct } from './Product';

export const productStore = {
	state: () => ({
		products: undefined,
	}),
	mutations: {
		setProducts(state, collection: Product[]) {
			state.products = collection;
		},
	},
	actions: {
		async loadProducts({ commit, state }) {
			if (state.products !== undefined) {
				return Promise.resolve();
			}

			commit('setProducts', await useProduct().getAllNoPagination());
		},
	},
	getters: {
		products(state): Product[] {
			return state.products || [];
		},
		productSubscriptions(state, getters): ProductSubscription[] {
			return getters
				.products
				.filter(product => product.type === 'SUBSCRIPTION') as ProductSubscription[]
			;
		},
		productAddons(state, getters): ProductAddon[] {
			return getters
				.products
				.filter(product => product.type === 'ADDON') as ProductAddon[]
			;
		},
	},
};
