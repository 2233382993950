import { addRoutes } from '@/router';

addRoutes([
	{
		path: '/ticket_status',
		name: 'ticket_status.index',
		component: () => import(/* webpackChunkName: "ticketStatus" */'./pages/TicketStatusIndex.vue'),
		auth: true,
	},
	{
		path: '/ticket_status/create',
		name: 'ticket_status.create',
		component: () => import(/* webpackChunkName: "ticketStatus" */'./pages/TicketStatusCreate.vue'),
		auth: true,
	},
	{
		path: '/ticket_status/:id/edit',
		name: 'ticket_status.edit',
		component: () => import(/* webpackChunkName: "ticketStatus" */'./pages/TicketStatusEdit.vue'),
		auth: true,
	},
]);