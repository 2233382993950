import { createRouter, createWebHistory, NavigationGuardWithThis, RouteRecordRaw } from 'vue-router';
import { redirectIfAuthenticated, requireAuth, requirePermission } from '@/core/routeGuards';

export const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: [],
});

export interface RouteInfo {
	path: RouteRecordRaw['path'];
	name: RouteRecordRaw['name'];
	component: RouteRecordRaw['component'];
	auth?: boolean;
	permissions?: string[];
}

export function addRoutes(routes: Array<RouteInfo>) {
	routes.forEach(r => {
		const beforeEnter: NavigationGuardWithThis<unknown>[] = [];

		if (r.auth === true) {
			beforeEnter.push(requireAuth);
		} else if (r.auth === false) {
			beforeEnter.push(redirectIfAuthenticated);
		}

		if (r.permissions) {
			beforeEnter.push(requirePermission(r.permissions));
		}

		router.addRoute({
			path: r.path,
			name: r.name,
			component: r.component,
			beforeEnter,
		} as RouteRecordRaw);
	});
}
