import { addRoutes } from '@/router';

addRoutes([
	{
		path: '/customer',
		name: 'customer.index',
		component: () => import(/* webpackChunkName: "customer" */'./pages/CustomerIndex.vue'),
		auth: true,
	},
	{
		path: '/customer/create',
		name: 'customer.create',
		component: () => import(/* webpackChunkName: "customer" */'./pages/CustomerCreate.vue'),
		auth: true,
	},
	{
		path: '/customer/:id/edit',
		name: 'customer.edit',
		component: () => import(/* webpackChunkName: "customer" */'./pages/CustomerEdit.vue'),
		auth: true,
	},
	{
		path: '/customer/:customer_id/user',
		name: 'customer.user.index',
		component: () => import(/* webpackChunkName: "customer" */'./pages/UserIndex.vue'),
		auth: true,
	},
	{
		path: '/customer/:customer_id/user/create',
		name: 'customer.user.create',
		component: () => import(/* webpackChunkName: "customer" */'./pages/UserCreate.vue'),
		auth: true,
	},
	{
		path: '/customer/:customer_id/user/:id/edit',
		name: 'customer.user.edit',
		component: () => import(/* webpackChunkName: "customer" */'./pages/UserEdit.vue'),
		auth: true,
	},
	{
		path: '/customer/:customer_id/cms_page',
		name: 'customer.cmsPage.index',
		component: () => import(/* webpackChunkName: "customer" */'./pages/CustomerCmsPageIndex.vue'),
		auth: true,
	},
	{
		path: '/customer/ticket-form',
		name: 'customer.ticketform',
		component: () => import(/* webpackChunkName: "dashboard" */'./pages/CustomerTicketFormInfoPage.vue'),
		auth: true,
	},
]);