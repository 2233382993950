import { addRoutes } from '@/router';

addRoutes([
	{
		path: '/partner',
		name: 'partner.index',
		component: () => import(/* webpackChunkName: "partner" */'./pages/PartnerIndex.vue'),
		auth: true,
	},
	{
		path: '/partner/create',
		name: 'partner.create',
		component: () => import(/* webpackChunkName: "partner" */'./pages/PartnerCreate.vue'),
		auth: true,
	},
	{
		path: '/partner/:id/edit',
		name: 'partner.edit',
		component: () => import(/* webpackChunkName: "partner" */'./pages/PartnerEdit.vue'),
		auth: true,
	},
	{
		path: '/partner/:id/whitelabel',
		name: 'partner.whitelabel',
		component: () => import(/* webpackChunkName: "partner" */'./pages/PartnerWhitelabel.vue'),
		auth: true,
	},
	{
		path: '/partner/:id/law_firm',
		name: 'partner.law_firm',
		component: () => import(/* webpackChunkName: "partner" */'./pages/PartnerLawFirm.vue'),
		auth: true,
	},
	{
		path: '/partner/law_firm',
		name: 'partner.me.law_firm',
		component: () => import(/* webpackChunkName: "partner" */'./pages/PartnerLawFirm.vue'),
		auth: true,
	},
	{
		path: '/partner/:partner_id/user',
		name: 'partner.user.index',
		component: () => import(/* webpackChunkName: "partner" */'./pages/UserIndex.vue'),
		auth: true,
	},
	{
		path: '/partner/:partner_id/user/create',
		name: 'partner.user.create',
		component: () => import(/* webpackChunkName: "partner" */'./pages/UserCreate.vue'),
		auth: true,
	},
	{
		path: '/partner/:partner_id/user/:id/edit',
		name: 'partner.user.edit',
		component: () => import(/* webpackChunkName: "partner" */'./pages/UserEdit.vue'),
		auth: true,
	},
]);