import { $gettext } from '@/gettext';
import store from '@/store';
import { ApiError } from '@/core/http/ApiError';

export interface FlashMessage {
	id: number;
	type: 'success' | 'warning' | 'danger';
	title: string | null;
	message: string;
	timeout: number | null;
}

export const Flash = {

	clear() {
		store.commit('clearFlash');
	},

	createMessage(flash: FlashMessage) {
		store.commit('addFlash', flash);
		if (flash.timeout) {
			setTimeout(() => store.commit('removeFlash', flash.id), flash.timeout);
		}
	},

	success(message: string, title: string | null = null, timeout: number | null = 5000): void {
		this.createMessage({
			id: Math.random(),
			type: 'success',
			title,
			message,
			timeout,
		});
	},

	warning(message: string, title: string | null = null, timeout: number | null = 10000): void {
		this.createMessage({
			id: Math.random(),
			type: 'warning',
			title,
			message,
			timeout,
		});
	},

	danger(message: string, title: string | null = null, timeout: number | null = null): void {
		this.createMessage({
			id: Math.random(),
			type: 'danger',
			title,
			message,
			timeout,
		});
	},

	fromError(error: Error, timeout: number | null = 20000): void {
		let title: string | null = null;
		let message = 'Okänt fel.';

		if (error instanceof ApiError) {
			message = error.message;
			if (error.isValidationError()) {
				title = $gettext('Valideringsfel');
				message = $gettext('Se mer information i formuläret');
			} else if (error.isRequestError()) {
				title = $gettext('Något gick fel med din förfrågan.');
			} else {
				title = 'Server Error';
			}
		}

		this.danger(message, title, timeout);
	},
};
