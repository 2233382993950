import { addRoutes } from '@/router';

addRoutes([
	{
		path: '/dashboard',
		name: 'dashboard',
		component: () => import(/* webpackChunkName: "dashboard" */'./pages/DashboardPage.vue'),
		auth: true,
	},
]);
