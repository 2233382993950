import ApiResource from '@/core/http/ApiResource';
import { ContentModel } from '@/core/languageContent';
import { UserType } from '@/modules/user/User';
import { $gettext } from '@/gettext';

export interface CmsPageContent extends ContentModel {
	headline: string;
	text: string;
}

export interface CmsPage {
	id: number;
	user_type: UserType[];
	content: CmsPageContent[];
	created_time: string;
}

export const cmsPageUserTypes = {
	PARTNER: $gettext('Återförsäljare'),
	LAW_FIRM: $gettext('Externa partners'),
	CUSTOMER: $gettext('Kunder'),
};

export const useCmsPage = () => new ApiResource<CmsPage>('/api/cms_page');
