import { addRoutes } from '@/router';

addRoutes([
	{
		path: '/ticket',
		name: 'ticket.index',
		component: () => import(/* webpackChunkName: "ticket" */'./pages/TicketIndex.vue'),
		auth: true,
	},
	{
		path: '/ticket/create',
		name: 'ticket.create',
		component: () => import(/* webpackChunkName: "ticket" */'./pages/TicketCreate.vue'),
		auth: true,
	},
	{
		path: '/ticket/:id/view',
		name: 'ticket.view',
		component: () => import(/* webpackChunkName: "ticket" */'./pages/TicketView.vue'),
		auth: true,
	},
]);