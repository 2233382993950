import { addRoutes } from '@/router';

addRoutes([
	{
		path: '/law_firm',
		name: 'law_firm.index',
		component: () => import(/* webpackChunkName: "lawFirm" */'./pages/LawFirmIndex.vue'),
		auth: true,
	},
	{
		path: '/law_firm/create',
		name: 'law_firm.create',
		component: () => import(/* webpackChunkName: "lawFirm" */'./pages/LawFirmCreate.vue'),
		auth: true,
	},
	{
		path: '/law_firm/:id/edit',
		name: 'law_firm.edit',
		component: () => import(/* webpackChunkName: "lawFirm" */'./pages/LawFirmEdit.vue'),
		auth: true,
	},
	{
		path: '/law_firm/:law_firm_id/user',
		name: 'law_firm.user.index',
		component: () => import(/* webpackChunkName: "lawFirm" */'./pages/UserIndex.vue'),
		auth: true,
	},
	{
		path: '/law_firm/:law_firm_id/user/create',
		name: 'law_firm.user.create',
		component: () => import(/* webpackChunkName: "lawFirm" */'./pages/UserCreate.vue'),
		auth: true,
	},
	{
		path: '/law_firm/:law_firm_id/user/:id/edit',
		name: 'law_firm.user.edit',
		component: () => import(/* webpackChunkName: "lawFirm" */'./pages/UserEdit.vue'),
		auth: true,
	},
	{
		path: '/law_firm/register/:hash',
		name: 'law_firm.public.register',
		component: () => import(/* webpackChunkName: "lawFirmPublic" */'./pages/PublicRegister.vue'),
		auth: false,
	},
]);