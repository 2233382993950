import { $gettext } from '@/gettext';
import auth from '@/core/Auth';
import { Flash } from '@/core/Flash';

export function requireAuth(to, from, next) {
	if (auth.isAuthenticated()) {
		next();
	} else {
		auth.setLoginRedirect(to.fullPath);
		next({ name: 'login' });
	}
}

export function redirectIfAuthenticated(to, from, next) {
	if (auth.isAuthenticated()) {
		if (auth.getLoginRedirect()) {
			next(auth.getLoginRedirect());
		} else {
			next({ name: 'dashboard' });
		}
	} else {
		next();
	}
}

export function requirePermission(permissions: string[], redirect = 'dashboard') {
	return function (to, from, next) {
		if (auth.canAll(permissions)) {
			next();
		} else {
			Flash.danger(
				$gettext('Du har inte behörighet att se den här sidan'),
				null,
				20000,
			);
			next({ name: redirect });
		}
	};
}
