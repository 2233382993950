import { addRoutes } from '@/router';

addRoutes([
	{
		path: '/ticket_category',
		name: 'ticket_category.index',
		component: () => import(/* webpackChunkName: "ticketCategory" */'./pages/TicketCategoryIndex.vue'),
		auth: true,
	},
	{
		path: '/ticket_category/create',
		name: 'ticket_category.create',
		component: () => import(/* webpackChunkName: "ticketCategory" */'./pages/TicketCategoryCreate.vue'),
		auth: true,
	},
	{
		path: '/ticket_category/:id/edit',
		name: 'ticket_category.edit',
		component: () => import(/* webpackChunkName: "ticketCategory" */'./pages/TicketCategoryEdit.vue'),
		auth: true,
	},
]);