import { ApiError } from '@/core/http/ApiError';
import { Flash } from '@/core/Flash';
import { Ref } from 'vue';
import { router } from '@/router';
import { RouteLocationRaw } from 'vue-router';

export interface ErrorBag {
	[key: string]: string[];
}

export function getValidationErrors(error: Error): ErrorBag {
	if (error instanceof ApiError && error.isValidationError()) {
		return error.getErrors();
	}

	return {};
}

export function handleFormError(error: Error, bag: Ref<ErrorBag>): void {
	Flash.fromError(error, 20000);
	bag.value = getValidationErrors(error);

	if (bag.value.form) {
		bag.value.form.forEach(err => {
			Flash.danger(err, null, 20000);
		});
	}
}

export function handleLoadError(error: Error, redirect: string, params = {}) {
	Flash.fromError(error, 20000);
	router.push({ name: redirect, params });
}

export type SelectOption = {
	value: string | number | null;
	label: string;
	disabled?: boolean;
}

export type TabId = {
	id: string;
	name: string;
}

export function sleep(time: number): Promise<void> {
	return new Promise(resolve => {
		setTimeout(resolve, time);
	});
}

export function routePath(name: string, params: Record<string, string | number> = {}): string {
	let url = `${location.protocol}//${location.hostname}`;
	if (location.port.length) {
		url += ':' + location.port;
	}

	return `${url}${router.resolve({ name, params }).href}`;
}

export type LinkBuilder<T> = (item: T) => RouteLocationRaw;
